import React from "react";
import { Alert, Spinner } from "reactstrap";
import { AdminSettings, BuzzEvent, ClientState, KeepAliveRequest, RequestPacket, RequestPayload, ResponsePacket, ResponsePayload, ServerState, StreamBuzzFeedRequest } from "../API";

interface IAPIClient {
    children: React.ReactNode;
    apiURL: string;
    session?: string;
    instance?: string;
    isDevice?: boolean;
}

interface StreamDef {
    request: RequestPayload,
    handler: (el: any) => void,
    reqId?: number,
}

export interface IAPIState {
    clientState: ClientState | null,
    serverState: ServerState | null,
    config: AdminSettings | null,
    platform: NodeBuzzClient | null,
    setError: (error: string | null) => void
}

class NodeBuzzClient {
    private reqId: number = 0
    private apiURL: string
    private ws?: WebSocket
    private reqHandlers: { [key: number]: (ev: any) => void } = {}
    private setError: (error: string) => void
    private setLoadingSpinner: (enabled: boolean) => void
    private queuedPackets: string[] = []
    private keepAliveRequest: KeepAliveRequest | null
    private keepAliveInterval: NodeJS.Timer
    private reconnectTimer?: NodeJS.Timer
    private streamDefs: StreamDef[]
    private canceled = false
    public buzzHandler: (ev: BuzzEvent) => void = (ev) => {}

    constructor(apiURL: string, setError: (error: string) => void, setLoadingSpinner: (enabled: boolean) => void, streams: StreamDef[], keepAliveRequest: KeepAliveRequest | null, buzzFeedReq: StreamBuzzFeedRequest | null) {
        // props.apiURL, setError, setLoadingSpinner, streams, keepAlive
        this.apiURL = apiURL
        this.setError = setError
        this.setLoadingSpinner = setLoadingSpinner
        this.keepAliveRequest = keepAliveRequest
        this.streamDefs = streams
        if (buzzFeedReq)
            this.streamDefs.push({
                request: buzzFeedReq,
                handler: (ev) => this.buzzHandler(ev)
            })

        // Delay websocket by a bit to work around spurious re-renders
        // TODO: FIXME: why?
        this.reconnectTimer = setTimeout(() => this.connect(), 100)

        this.keepAliveInterval = setInterval(async () => {
            console.log("keepalive interval")
            if (this.keepAliveRequest) {
                let reqId = this.reqId
                let cancel = () => {};
                let res: Promise<ResponsePayload> = new Promise((resolve, _cancel) => {
                    cancel = _cancel;
                    this.reqHandlers[reqId] = resolve
                })
                let cancelTimer = setTimeout(cancel, 1000)
                console.log("keepalive req", reqId, performance.now())
                this.send(this.keepAliveRequest)
                try {
                    await res
                    console.log("keepalive res", reqId, performance.now())
                clearTimeout(cancelTimer)
                } catch {
                    console.warn("keepalive fail", reqId, performance.now())
                    console.log("calling close on", this.ws)
                    this.ws?.close()
                    this.ws = undefined
                    this.onWsClose()
                }
                // TODO: measure latency?
            }
        }, 7000)
    }

    cancel() {
        this.canceled = true
        clearInterval(this.keepAliveInterval)
        if (this.reconnectTimer !== undefined)
            clearTimeout(this.reconnectTimer)
        if (this.ws)
            this.ws.close()
        this.ws = undefined
    }

    connect() {
        if (this.reconnectTimer !== undefined) clearTimeout(this.reconnectTimer)
        this.reconnectTimer = undefined
        if (this.canceled) return;
        if (this.ws) this.ws.close()
        const ws = new WebSocket(this.apiURL)
        this.ws = ws
        ws.onopen = () => {
            for (const packet of this.queuedPackets)
                ws.send(packet)
            this.queuedPackets = []
            this.setLoadingSpinner(false)

            for (const streamDef of this.streamDefs) {
                streamDef.reqId = this.reqId
                this.send(streamDef.request)
            }
        }

        ws.onmessage = (e) => {
            // console.log('Message:', e.data)
            let resp: ResponsePacket = JSON.parse(e.data)
            for (const streamDef of this.streamDefs) {
                if (streamDef.reqId === resp.id && resp.streamVal)
                    streamDef.handler(resp.streamVal)
                if (streamDef.reqId === resp.id && !resp.streamVal)
                    this.setLoadingSpinner(true)
            }
            if (resp.id in this.reqHandlers && resp.response)
                this.reqHandlers[resp.id](resp.response)
            delete this.reqHandlers[resp.id]
            if (resp.error)
                    this.setError(resp.error)
        }
      
        ws.onclose = (e) => {
          console.log('Socket is closed (onclose). Reconnect will be attempted in 2 seconds.', e.reason)
          this.onWsClose()
        }
      
        ws.onerror = (err) => {
          console.error('Socket encountered error: ', err.type, 'Closing socket')
          this.onWsClose()
        }
      }
    onWsClose() {
        if (this.canceled) return;
        if (this.reconnectTimer) return;
        this.reconnectTimer = setTimeout(()=> this.connect(), 2000)
        this.ws = undefined
        this.setLoadingSpinner(true)
    }

    send(payload: RequestPayload) {
        let packet: RequestPacket = {
            id: this.reqId,
            payload
        }
        this.reqId += 1
        try {
            if (!this.ws)
                console.error("trying to send request with disconnected websocket")
            if (this.ws?.readyState === this.ws?.CONNECTING && this.queuedPackets.length < 20)
                this.queuedPackets.push(JSON.stringify(packet))
            else
                this.ws?.send(JSON.stringify(packet))
        } catch (e) {
            console.error(e)
        }
    }

    request(payload: RequestPayload): Promise<ResponsePayload> {
        let reqId = this.reqId
        let res: Promise<ResponsePayload> = new Promise((resolve, _cancel) => {
            this.reqHandlers[reqId] = resolve
        })
        this.send(payload)
        return res
    }
}

const APIState = React.createContext<IAPIState>({
    clientState: null,
    serverState: null,
    config: null,
    platform: null,
    setError: _ => { }
});


const APIClient: React.FC<IAPIClient> = (props) => {
    const [clientState, setClientState] = React.useState<ClientState | null>(null);
    const [serverState, setServerState] = React.useState<ServerState | null>(null);
    const [config, setConfig] = React.useState<AdminSettings | null>(null);
    const [platform, setPlatform] = React.useState<NodeBuzzClient | null>(null);

    const [error, setError] = React.useState<string | null>(null);
    const [loadingSpinner, setLoadingSpinner] = React.useState<boolean>(true)

    React.useEffect(() => {
        const streams: StreamDef[] = []

        if (props.isDevice && props.session) {
            streams.push({ request: { kind: 'StreamClientState', session: props.session }, handler: setClientState })
        }

        if (props.session || props.instance) {
            streams.push({ request: {
                kind: 'StreamServerState',
                session: props.session,
                instance: props.instance
            }, handler: setServerState})

            streams.push({request: {
                kind: 'StreamSettings',
                session: props.session,
                instance: props.instance
            }, handler: setConfig})
        }

        const keepAlive: KeepAliveRequest = props.isDevice && props.session ? {
            kind: 'KeepAlive',
            session: props.session
        } : {
            kind: 'KeepAlive',
            noop: true
        }

        const buzzFeedReq: StreamBuzzFeedRequest | null = props.session || props.instance ? {
            kind: 'StreamBuzzFeed',
            session: props.session,
            instance: props.instance
        } : null

        const platform = new NodeBuzzClient(props.apiURL, setError, setLoadingSpinner, streams, keepAlive, buzzFeedReq)
        setPlatform(platform);

        return () => platform.cancel()
    }, [props.apiURL, props.session, props.instance, props.isDevice]);

    return (
        <React.Fragment>
            <Alert color="danger" isOpen={error !== null} toggle={() => setError(null)}>
                {error}
            </Alert>
            {loadingSpinner ? <Spinner style={{ position: "absolute", left: '1em', top: '1em' }} color="light" /> : null}
            <APIState.Provider value={{
                platform,
                clientState,
                serverState,
                config,
                setError
            }}>
                {props.children}
            </APIState.Provider>
        </React.Fragment>
    )
};

export { APIClient, APIState };
