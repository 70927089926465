import React, { useContext } from 'react';
import { HashRouter as Router, Navigate, Route, Routes, useParams } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';

import './App.css';
import { APIClient } from './State/APIClient';
import { TeamSelection } from './TeamSelection/TeamSelection';
import { Session } from "./Prerequisites/Session";
import { Configure } from "./Configure/Configure"
import { Settings as SettingsStateProvider, SettingsState } from './State/Settings';
import { BuzzerView } from './Buzzer/Buzzer';
import { Monitor } from './Monitor/Monitor';
import { BuzzTimings } from './BuzzTimings';
import { Admin } from './Admin/Admin';

const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const apiUrl = process.env.REACT_APP_API_URL || (isDevEnv ? `ws://${window.location.hostname}:42593/api` : window.location.origin.replace(/^http/, 'ws') + "/api")

const Index: React.FC = () => {
  let settings = useContext(SettingsState)
  if (!settings.session)
    return <Navigate to="/session" replace />

  return <Navigate to="/teams" replace />
}

const Logout: React.FC = () => {
  let settings = useContext(SettingsState)
  settings.setSession(null)
  settings.setAdminSession(null)
  return <Navigate to="/session" replace />
}

const WithAPI: React.FC<{ admin?: boolean, user?: boolean, instance?: string, children: React.ReactNode }> = ({ admin, user, instance, children }) => {
  let settings = useContext(SettingsState)
  let session = undefined
  if (admin)
    session = settings.adminSession
  if (user)
    session = settings.session
  if (session === null) {
    if (user)
      return <Navigate to="/session" replace />
    session = undefined
  }
  return (
    <APIClient apiURL={apiUrl} session={session} instance={instance} isDevice={user}>
      {children}
    </APIClient>
  )
}

const SessionInstance: React.FC = () => {
  const { instance } = useParams()
  return <WithAPI><Session instanceStatic={instance}/></WithAPI>
}

const MonitorInstance: React.FC = () => {
  const { instance } = useParams()
  return <WithAPI instance={instance}><Monitor /></WithAPI>
}

const BuzzTimingsInstance: React.FC = () => {
  const { instance } = useParams()
  return <WithAPI instance={instance}><BuzzTimings /></WithAPI>
}

const App: React.FC = () => {
  return (
    <div className="App" style={{ padding: '10px', margin: '10px' }}>
      <SettingsStateProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/session" element={<WithAPI><Session /></WithAPI>} />
            <Route path="/session/:instance" element={<SessionInstance />} />
            <Route path="/join" element={<WithAPI><Session /></WithAPI>} />
            <Route path="/join/:instance" element={<SessionInstance />} />

            <Route path="/buzzer" element={<WithAPI user><BuzzerView /></WithAPI>} />
            <Route path="/teams" element={<WithAPI user><TeamSelection /></WithAPI>} />
            <Route path="/configure" element={<WithAPI user><Configure /></WithAPI>} />

            <Route path="/monitor/:instance" element={<MonitorInstance />} />
            <Route path="/buzz-timings/:instance" element={<BuzzTimingsInstance />} />
            <Route path="/admin" element={<WithAPI admin><Admin /></WithAPI>} />
          </Routes>
        </Router>
      </SettingsStateProvider>
    </div>
  );
}

export default App;
