
import React, { useContext, useState } from 'react';
import { ListGroup, ListGroupItem, Button, Container, Row, Alert } from 'reactstrap';
import { CheckIcon, ListUnorderedIcon, PencilIcon, PlusIcon } from '@primer/octicons-react'
import { APIState } from '../State/APIClient';
import { Link, Navigate } from 'react-router-dom';
import { SettingsState } from '../State/Settings';
import { SmolCol } from '../Buzzer/Buzzer';
import { NeedData } from '../Prerequisites/NeedData';
import { tsToDate } from '../API';

export const TeamSelection: React.FC = () => {
    let api = useContext(APIState)
    let { platform, clientState, serverState, config } = api
    let settings = useContext(SettingsState)
    let [teamsOpen, setTeamsOpen] = useState<boolean>(false);

    let allowTeamChanging = config ? config.allowTeamChanging : true
    teamsOpen = teamsOpen && allowTeamChanging

    if (settings.session === null)
        return <Navigate to="/" replace />

    if (!platform || !clientState || !serverState)
        return <NeedData />

    let team = clientState.ownTeam
    let teams = serverState.teams

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Link to="/buzzer">
                        <Button style={{ float: 'right' }}>
                            <CheckIcon size="medium" />
                        </Button>
                    </Link>
                </SmolCol>
            </Row>
            <Row>
                <SmolCol>
                    <Alert color="primary" style={{ fontSize: '1.4em' }}>Your are in:<br />{team.name}</Alert>
                </SmolCol>
            </Row>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Button block size="lg" onClick={() => {
                        let name = window.prompt("New Team Name?")
                        if (!name)
                            return
                        
                        api.platform?.send({
                            kind: 'CreateTeam',
                            session: settings.session!,
                            name
                        })
                    }} disabled={settings.session === null || !allowTeamChanging}>
                        Open a new team
                        <span style={{ float: 'right' }}>
                            <PlusIcon size="medium" />
                        </span>
                    </Button>
                </SmolCol>
            </Row>
            <Row>
                <SmolCol>
                    <Button block size="lg" color={teamsOpen ? "dark" : undefined} onClick={() => setTeamsOpen(!teamsOpen)} disabled={!allowTeamChanging}>
                        Join a team <span style={{ float: 'right' }}><ListUnorderedIcon size="medium" /></span>
                    </Button>
                    {teamsOpen ? (
                        <ListGroup>
                            {teams.map(listTeam => {
                                const isOwnTeam = team && listTeam.uuid === team.uuid
                                let keys = Object.keys(listTeam.devices).sort()
                                let devices = keys.map(k => listTeam.devices[k])
                                return <React.Fragment key={listTeam.uuid}>
                                    <ListGroupItem style={{
                                        textAlign: 'left',
                                        backgroundColor: isOwnTeam ? '#df691a' : 'grey',
                                        color: 'white',
                                        userSelect: 'none',
                                    }} key={listTeam.uuid} onClick={() => {
                                        api.platform?.send({
                                            kind: 'JoinTeam',
                                            session: settings.session!,
                                            teamId: listTeam.uuid,
                                        })
                                    }}>
                                        {listTeam.name}
                                        {!listTeam.isLobby && isOwnTeam
                                            ? <Button style={{
                                                backgroundColor: 'transparent',
                                                borderColor: 'transparent',
                                                marginLeft: '0.5em',
                                                padding: 0 // TODO: set `vertical-align: initial` on svg element
                                            }}
                                                onClick={() => {
                                                    let name = window.prompt("New Teamname?")
                                                    if (name === null) return
                                                    api.platform?.send({
                                                        kind: 'RenameTeam',
                                                        session: settings.session!,
                                                        newName: name,
                                                    })
                                                }}>
                                                <PencilIcon />
                                            </Button>
                                            : null}
                                        <span style={{ textAlign: 'right', float: 'right' }}>
                                            {devices.length}
                                            {devices.length === 1 ? ' member' : ' members'}
                                        </span>
                                    </ListGroupItem>
                                    {
                                        isOwnTeam
                                            ? devices.map(device => {
                                                let online = tsToDate(device.lastSeenTimestamp).getTime() > new Date().getTime() - 13000
                                                return <ListGroupItem style={{
                                                    textAlign: "right",
                                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                                    color: online ? 'white' : 'red'
                                                }} key={device.uuid}>
                                                    {device.name}
                                                </ListGroupItem>
                                            })
                                            : null
                                    }
                                </React.Fragment>
                            })}
                        </ListGroup>
                    ) : null}
                </SmolCol>
            </Row>
        </Container >
    )
}