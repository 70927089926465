import React, { useState } from "react";
import { SoundCheck } from "../Prerequisites/SoundCheck";

interface ISettings {
    children: React.ReactNode;
}

export interface ISettingsState {
    soundCheck: boolean,
    completeSoundCheck: () => void,
    session: string | null,
    setSession: (session: string | null) => void,
    adminSession: string | null,
    setAdminSession: (session: string | null) => void,
    sensitivity: number,
    setSensitivity: (sens: number) => void,
}

const SettingsState = React.createContext<ISettingsState>(null as any);

const Settings: React.FC<ISettings> = (props) => {
    let [session, setSession] = useState(localStorage.getItem("nodebuzz/session"))
    let [adminSession, setAdminSession] = useState(localStorage.getItem("nodebuzz/adminSession"))
    let [soundCheck, setSoundCheck] = useState(false) // reset soundCheck for every visit
    let [sensitivity, setSensitivity] = useState(() => {
        let sens = localStorage.getItem("nodebuzz/sensitivity")
        return sens ? parseFloat(sens) : 0.5
    })

    return (
        <React.Fragment>
            <SettingsState.Provider value={{
                soundCheck,
                session,
                adminSession,
                sensitivity,
                completeSoundCheck: () => setSoundCheck(true),
                setSession: session => {
                    if (session)
                        localStorage.setItem("nodebuzz/session", session)
                    else
                        localStorage.removeItem("nodebuzz/session")
                    setSession(session)
                },
                setAdminSession: session => {
                    if (session)
                        localStorage.setItem("nodebuzz/adminSession", session)
                    else
                        localStorage.removeItem("nodebuzz/adminSession")
                    setAdminSession(session)
                },
                setSensitivity: sens => {
                    localStorage.setItem("nodebuzz/sensitivity", sens.toString())
                    setSensitivity(sens)
                }
            }}>
                {soundCheck ? props.children : <SoundCheck />}
            </SettingsState.Provider>
        </React.Fragment>
    )
};

export { Settings, SettingsState };