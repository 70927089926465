import React, { useContext, useEffect } from 'react'
import { Container, Row, Col, Input, Button, InputGroup, InputGroupText, Progress } from 'reactstrap'
import { APIState, IAPIState } from '../State/APIClient'
import { SettingsState, ISettingsState } from '../State/Settings'
import { Login as AdminLogin } from './Login'
import { Teams as AdminTeams } from './Teams'
import { Link } from 'react-router-dom'
import { GlobalHotKeys } from "react-hotkeys";
import { NeedData } from '../Prerequisites/NeedData'
import { CheckIcon, XIcon } from '@primer/octicons-react'
import { AdminSettings, BuzzEvent } from '../API'
import { audioNodes } from '../Prerequisites/SoundCheck'


const keyMap = {
    INC_POINTS: "plus",
    DEC_POINTS: "-",
    TOGGLE_REENABLE: 'o',
    TOGGLE_BUZZER: 'space',
    TOGGLE_TEAM_CHANGE: 't',
    BUZZ_RANDOM_TEAM: 'r',
    EXCLUDE_CURRENT_TEAM: 'e'
};

function updateSettings(api: IAPIState, settings: ISettingsState, update: Partial<AdminSettings>) {
    if (api.config === null)
        throw new Error("updateSettings with api.config === null")
    const config = { ...api.config, ...update }
    api.platform?.send({
        kind: 'UpdateSettings',
        session: settings.adminSession!,
        settings: config,
    })
}


export const Config: React.FC = () => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)
    if (!api.config)
        return <div>missing admin config</div>
    let config = api.config

    let update = (update: Partial<AdminSettings>) => updateSettings(api, settings, update)

    let Checkbox: React.FC<{
        children: React.ReactNode,
        checked: boolean,
        onChange: (checked: boolean) => void
    }> = ({ children, checked, onChange }) =>
            <InputGroup>
                <InputGroupText style={{ padding: 0 }}>
                    <Button style={{
                        backgroundColor: checked ? '#b15315' : '#df691a',
                        color: 'white'
                    }} onClick={_ => onChange(!checked)}>
                        {checked ? <CheckIcon /> : <XIcon />}
                    </Button>
                </InputGroupText>
                <InputGroupText style={{
                    color: '#ebebeb',
                    backgroundColor: '#4e5d6c',
                }}>{children}</InputGroupText>
            </InputGroup>

    return (
        <>
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroupText>Timeout</InputGroupText>
                        <Input type="number" value={config.timeout} onChange={e => update({ timeout: parseInt(e.target.value) })} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroupText>Re-arm delay</InputGroupText>
                        <Input type="number" value={config.rearmAfter} onChange={e => update({ rearmAfter: parseInt(e.target.value) })} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox checked={config.autoReenable} onChange={autoReenable => update({ autoReenable })}>
                        Auto re-enable the buzzers!
                    </Checkbox>
                </Col>
                <Col>
                    <Checkbox checked={config.soundEnabled} onChange={soundEnabled => update({ soundEnabled })}>
                        Play sounds in admin interface
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox checked={config.allowTeamChanging} onChange={allowTeamChanging => update({ allowTeamChanging })}>
                        Allow changing teams
                    </Checkbox>
                </Col>
                <Col>
                    <Checkbox checked={config.hideDevices} onChange={hideDevices => update({ hideDevices })}>
                        Hide devices in admin view
                    </Checkbox>
                </Col>
            </Row>
        </>
    )
}

export const Admin: React.FC = () => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)

    let soundEnabled = api.config ? api.config.soundEnabled : false

    useEffect(() => {
        if (!api.platform || !settings.adminSession) return

        api.platform.buzzHandler = (_event: BuzzEvent) => {
            console.log("admin buzzHandler", _event, "soundEnabled", soundEnabled)
            if (soundEnabled)
                audioNodes.buzzer?.play()
        }
    }, [settings.adminSession, api.platform, soundEnabled])

    if (settings.adminSession === null)
        return <AdminLogin />
    let adminSession = settings.adminSession

    if (api.config === null || api.serverState === null)
        return <NeedData />

    let buzzersEnabled = api.serverState.buzzersEnabled

    let lastWinner = api.serverState.teams.find(team => team.uuid === api.serverState!.lastWinner)

    const Cooldown: React.FC<{ cooldown?: number, timeout: number }> = ({ cooldown, timeout }) => {
        if (cooldown === undefined)
            return null
        return <span>
            <div style={{ textAlign: 'center' }}>Cooldown: {cooldown}s</div>
            <Progress animated value={Math.round((1 - (cooldown / timeout)) * 100)} />
        </span>
    }

    const handlers = {
        INC_POINTS: () => {
            let team = api.serverState!.teams.find(team => team.uuid === api.serverState!.lastWinner);
            if (!team)
                throw new Error("INC_POINTS without lastWinner team")
            api.platform?.send({
                kind: 'SetPoints',
                session: adminSession,
                teamId: team.uuid,
                points: team.score + 1
            })
        },
        DEC_POINTS: () => {
            let team = api.serverState!.teams.find(team => team.uuid === api.serverState!.lastWinner);
            if (!team)
                throw new Error("INC_POINTS without lastWinner team")
            api.platform?.send({
                kind: 'SetPoints',
                session: adminSession,
                teamId: team.uuid,
                points: team.score - 1
            })
        },
        TOGGLE_BUZZER: (event?: KeyboardEvent) => {
            if (event)
                event.preventDefault()
            api.platform?.send({
                kind: 'SetBuzzersEnabled',
                session: adminSession,
                enabled: !api.serverState!.buzzersEnabled
            })
        },
        TOGGLE_REENABLE: () => {
            updateSettings(api, settings, { autoReenable: !api.config!.autoReenable })
        },
        TOGGLE_TEAM_CHANGE: () => {
            updateSettings(api, settings, { allowTeamChanging: !api.config!.allowTeamChanging })
        },
        BUZZ_RANDOM_TEAM: () => {
            api.platform?.send({
                kind: 'BuzzRandomTeam',
                session: adminSession
            })
        },
        EXCLUDE_CURRENT_TEAM: () => {
            let team = api.serverState!.teams.find(team => team.uuid === api.serverState!.lastWinner);
            if (!team)
                throw new Error("EXCLUDE_CURRENT_TEAM without lastWinner team")
            api.platform?.send({
                kind: 'ChangeExclusion',
                session: adminSession,
                teamId: team.uuid,
                excluded: !team.excluded
            })
        }
    };

    return <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges><Container>
        <section className="bg-light rounded px-3 px-sm-4 py-3 py-sm-5" style={{ paddingTop: '1em' }}>
            <h1>ReactBuzz 3.141 <span role="img" aria-label="b">🅱️</span>remium Admin Interface</h1>
            <h2>
                {api.serverState.instanceId}
                -
                <Link to={"/monitor/" + api.serverState.instanceId}>Monitor</Link>
                -
                <Link to={"/buzz-timings/" + api.serverState.instanceId}>Buzz Timings</Link>
                -
                <Link to={"/join/" + api.serverState.instanceId}>Join URL</Link>
                -
                <Link to="/logout">Logout</Link>
            </h2>
            <Row><h2>Buzzer Control</h2></Row>
            <Row>
                <Col md={{ size: 4 }}>
                    <Button block
                        style={{ backgroundColor: buzzersEnabled ? '#df691a' : undefined }}
                        onClick={() => {
                            api.platform?.send({
                                kind: 'SetBuzzersEnabled',
                                session: adminSession,
                                enabled: !buzzersEnabled
                            })
                        }}>{buzzersEnabled ? 'Disable ' : 'Enable '} Buzzers!</Button>
                </Col>
                <Col md={{ size: 4 }}>
                    <Button block onClick={() => {
                        api.platform?.send({
                            kind: 'BuzzRandomTeam',
                            session: adminSession
                        })
                    }}>Buzz a random Team</Button>
                </Col>
                <Col md={{ size: 4 }}>
                    <Button block onClick={() => {
                        if (window.prompt("Confirm Reset? (type 'y')") !== 'y')
                            return
                        api.platform?.send({
                            kind: 'ResetPoints',
                            session: adminSession
                        })
                    }}>Reset Points</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Cooldown cooldown={api.serverState.cooldown} timeout={api.config.timeout} />
                </Col>
            </Row>
            {lastWinner ? (
                <>
                    <Row><h2>Last Buzz Winner Team</h2></Row>
                    <Row style={{ textAlign: 'center' }}>
                        <span style={{ width: '100%' }}>
                            <h5>{lastWinner.name}</h5>
                            <h6>
                                (
                                {lastWinner.score}
                                {lastWinner.score === 1 ? ' point' : ' points'}
                                )
                            </h6>
                        </span>
                    </Row>
                </>
            ) : null}
            <Row><h2>Configuration</h2></Row>
            <Config />
            <Row><h2>List of Teams</h2></Row>
            <AdminTeams />
        </section>
    </Container>
    </GlobalHotKeys>
}