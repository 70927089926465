
import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Alert } from 'reactstrap';
import { SmolCol } from '../Buzzer/Buzzer';
import { APIState } from '../State/APIClient';
import { Link } from 'react-router-dom';

export const NeedData: React.FC = () => {
    let api = useContext(APIState)
    let [showLogout, setShowLogout] = useState(false)
    useEffect(() => {
        let timer = setTimeout(() => setShowLogout(true), 2000)
        return () => clearTimeout(timer)
    })
    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    {showLogout
                        ? <Alert color="primary">
                            Check your network connection. <br />
                            If this issue persists, you can {' '}
                            <Link to="/logout">try logging out</Link>.
                        </Alert>
                        : <Alert color="primary">{!api.platform ? 'Connecting...' : 'Waiting for data...'}</Alert>
                    }
                </SmolCol>
            </Row>
        </Container>
    )
}