import React, { useState, useEffect } from 'react'

export let handlerInstance: DeviceMotionHandler | null = null

type APIStatus = 'UNSUPPORTED' | 'NO_DATA' | 'PERMISSION_GRANTED' | 'PERMISSION_DENIED' | 'WORKING'
interface IAPIStatus {
    deviceMotionEvents: APIStatus,
    sensors: APIStatus
}
interface IMotionHandler {
    sensitivity: number,
    onBuzz: () => void,
    onAPIStatus?: (apiStatus: IAPIStatus) => void
}
export class DeviceMotionHandler {
    public onBuzz?: () => void
    public sensitivity: number
    public onAPIStatus?: (apiStatus: IAPIStatus) => void

    private maxMotion?: number = undefined
    private lastMotion: number = 0
    private apiStatus: IAPIStatus = {
        sensors: 'NO_DATA',
        deviceMotionEvents: 'NO_DATA'
    }
    private lastBuzz: number
    private accelerometer?: any // TODO

    constructor(props: IMotionHandler) {
        this.lastBuzz = performance.now()

        this.deviceMotionListener = this.deviceMotionListener.bind(this)

        console.log("setting up motion listener")

        if ("Accelerometer" in window && navigator.permissions) {
            // Type '"accelerometer"' is not assignable to type 'PermissionName'
            // https://github.com/microsoft/TypeScript/issues/33923
            const name = 'accelerometer' as PermissionName
            navigator.permissions.query({ name })
                .then(status => {
                    console.log(status)
                })
                .catch(x => {
                    console.error(x)
                })
        } else {
            this.apiStatus.sensors = 'UNSUPPORTED'
        }

        window.addEventListener("devicemotion", this.deviceMotionListener)
        this.requestPermission()

        this.sensitivity = props.sensitivity
        this.updateProps(props)
    }

    updateProps(props: IMotionHandler) {
        this.onBuzz = props.onBuzz
        this.onAPIStatus = props.onAPIStatus
        this.sensitivity = props.sensitivity

        if (this.onAPIStatus)
            this.onAPIStatus(this.apiStatus)
    }

    deviceMotionListener(event: DeviceMotionEvent) {
        let accel = event.acceleration;
        if (accel === null) return;
        let absX = Math.abs(accel.x || 0);
        let absY = Math.abs(accel.y || 0);
        let absZ = Math.abs(accel.z || 0);
        let motion = Math.max(absX, absY, absZ);
        motion = Math.round(motion * 100) / 100;

        if (motion !== 0 && this.apiStatus.deviceMotionEvents !== 'WORKING') {
            this.apiStatus = { ...this.apiStatus, deviceMotionEvents: 'WORKING' }
            if (this.onAPIStatus)
                this.onAPIStatus(this.apiStatus)
        }

        this.lastMotion = motion
        if (motion > (this.maxMotion || 0))
            this.maxMotion = motion

        this.checkBuzz()
    }

    checkBuzz() {
        if (this.sensitivity === 0) return; // motion events are disabled
        if (this.maxMotion !== undefined && this.maxMotion > this.sensitivity) {
            let now = performance.now()
            // debounce at 4 bps
            if (now - this.lastBuzz < 250) return
            this.lastBuzz = now
            this.maxMotion = undefined
            if (this.onBuzz)
                this.onBuzz()
            else
                console.log("buzz event without onBuzz handler")
        }
    }

    get() {
        let maxMotion = this.maxMotion !== undefined ? this.maxMotion : this.lastMotion
        this.maxMotion = undefined
        maxMotion /= 2
        return Math.round(maxMotion * 100) / 100;
    }

    cleanup() {
        console.log("dropping motion listener")
        window.removeEventListener("devicemotion", this.deviceMotionListener)
    }

    // iOS 13: should be triggered by a user guesture
    requestPermission() {
        if (!window.DeviceMotionEvent) return;
        if (typeof (window.DeviceMotionEvent as any).requestPermission === 'function')
            (window.DeviceMotionEvent as any).requestPermission()
                .then((response: any) => {
                    if (response === 'granted') {
                        this.apiStatus.deviceMotionEvents = 'PERMISSION_GRANTED'
                    } else {
                        this.apiStatus.deviceMotionEvents = 'PERMISSION_DENIED'
                    }
                    if (this.onAPIStatus)
                        this.onAPIStatus(this.apiStatus)
                }).catch(() => {
                    this.apiStatus.deviceMotionEvents = 'PERMISSION_DENIED'
                    if (this.onAPIStatus)
                        this.onAPIStatus(this.apiStatus)
                })
    }
}



const DeviceMotionState = React.createContext<{
    apiStatus: IAPIStatus,
    sensitivity: number,
    buzzCount: number,
    getMaxMotion: () => number
}>(null as any);

const DeviceMotionProvider: React.FC<{ children: React.ReactNode, onBuzz?: () => void, sensitivity: number }> = ({
    onBuzz, children, sensitivity
}) => {
    let [apiStatus, setAPIStatus] = useState<IAPIStatus>({
        deviceMotionEvents: 'NO_DATA',
        sensors: 'NO_DATA'
    })
    let [getMaxMotion, setGetMaxMotion] = useState<() => number>(() => () => 0)
    let [buzzCount, setBuzzCount] = useState(0)

    useEffect(() => {
        let props = {
            onAPIStatus: setAPIStatus,
            onBuzz: () => {
                setBuzzCount(b => b + 1)
                if (onBuzz)
                    onBuzz()
            },
            sensitivity
        }
        if (handlerInstance === null)
            handlerInstance = new DeviceMotionHandler(props)
        else
            handlerInstance.updateProps(props)

        setGetMaxMotion(() => () => {
            return handlerInstance ? handlerInstance.get() : 0
        })

        return () => {
            if (handlerInstance)
                handlerInstance.onBuzz = undefined
        }
    }, [onBuzz, sensitivity])

    return (
        <DeviceMotionState.Provider value={{
            sensitivity,
            apiStatus,
            getMaxMotion,
            buzzCount
        }}>
            {children}
        </DeviceMotionState.Provider>
    )
};

export { DeviceMotionProvider, DeviceMotionState };