import React, { useState, useContext } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ButtonGroup } from 'reactstrap'
import { JoinInstanceResponse, SpawnInstanceResponse } from '../API'
import { APIState } from '../State/APIClient'
import { SettingsState } from '../State/Settings'

export const Login: React.FC = () => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)
    let [instance, setInstance] = useState("quiznight-xx")
    let [authKey, setAuthKey] = useState("")


    return <Container>
        <Row style={{ paddingBottom: '1em' }}>
            <Col>
                    <Form style={{ textAlign: 'left', color: 'white' }}>
                        <FormGroup>
                            <Label>Instance:</Label>
                            <Input value={instance} onChange={e => setInstance(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                            <Label>AuthKey:</Label>
                            <Input value={authKey} onChange={e => setAuthKey(e.target.value)} />
                        </FormGroup>

                        <ButtonGroup style={{ float: 'right' }}>
                            <Button disabled={!api.platform} onClick={async () => {
                                let res = await api.platform?.request({
                                    kind: "SpawnInstance",
                                    instance,
                                    authKey
                                }) as SpawnInstanceResponse
                                settings.setAdminSession(res.session)
                            }}
                                style={{
                                    borderRight: 'black',
                                    borderRightStyle: 'solid',
                                }}
                            >Spawn Instance</Button>
                            <Button disabled={!api.platform} onClick={async () => {
                                let res = await api.platform?.request({
                                    kind: "JoinInstance",
                                    instance,
                                    authKey
                                }) as JoinInstanceResponse
                                settings.setAdminSession(res.session)
                            }}>Join Instance</Button>
                        </ButtonGroup>
                    </Form>
            </Col>
        </Row>
    </Container>


}