
import React, { useContext, useState } from 'react';
import { Button, Container, Col, Row, Input, Form, FormGroup, Label } from 'reactstrap';
import { APIState } from '../State/APIClient';
import { SettingsState } from '../State/Settings';
import { Navigate } from 'react-router';
import { JoinInstanceResponse } from '../API';

export const Session: React.FC<{instanceStatic?: string}> = ({ instanceStatic }) => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)
    let [instance, setInstance] = useState('quiznight-xx')
    let [name, setName] = useState(() => 'Device ' + Math.random().toString(16).slice(2))
    if (instanceStatic)
        instance = instanceStatic


    if (settings.session !== null)
        return <Navigate to="/" replace />

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <Col xs="12" sm={{ offset: 4, size: 4 }}>
                    <Form style={{ textAlign: 'left', color: 'white' }}>
                        <FormGroup>
                            <Label>Game Instance:</Label>
                            <Input onChange={e => {
                                setInstance(e.target.value)
                            }} value={instance} disabled={instanceStatic !== undefined}></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Device Name:</Label>
                            <Input onChange={e => {
                                setName(e.target.value)
                            }} value={name}></Input>
                        </FormGroup>
                        <Button block onClick={async () => {
                            let res = await api.platform?.request({
                                kind: 'JoinInstance',
                                instance,
                                name
                            }) as JoinInstanceResponse
                            settings.setSession(res.session)
                        }}>Join</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}