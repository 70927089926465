import React, { useContext, useState } from 'react'
import { APIState } from '../State/APIClient'
import { ListGroup, ListGroupItem, Input, InputGroup, InputGroupText, Badge, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import { DndProvider, useDrag, useDrop, DragSourceMonitor } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SettingsState } from '../State/Settings'
import { GearIcon } from '@primer/octicons-react'
import { Device, Team, tsToDate } from '../API'

const TeamMember: React.FC<{ member: Device }> = ({ member }) => {
    let settings = useContext(SettingsState)
    let api = useContext(APIState)
    const [{ isDragging }, drag] = useDrag({
        item: { name: member.uuid, type: 'DEVICE' },
        end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
            const dropResult: { name: string } | null = monitor.getDropResult()
            if (item && dropResult) {
                api.platform?.send({
                    kind: "JoinTeam",
                    session: settings.adminSession!,
                    deviceUuid: item.name,
                    teamId: dropResult.name
                })
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        type: 'DEVICE'
    })

    if (isDragging)
        console.log('testdrag')

    let online = tsToDate(member.lastSeenTimestamp).getTime() > new Date().getTime() - 13000
    return <span ref={drag} key={member.uuid} style={{ cursor: 'move' }}>
        <ListGroupItem style={{
            textAlign: "right",
            backgroundColor: 'rgba(0,0,0,0.2)',
            color: isDragging ? 'white' : 'black'
        }} key={member.uuid}>
            {member.name} {' '}
            <Badge color={online ? 'success' : 'danger'}>{online ? 'Online' : 'Offline'}</Badge>
        </ListGroupItem>
    </span>
}

const TeamEntry: React.FC<{ team: Team }> = ({ team }) => {
    let settings = useContext(SettingsState)
    let api = useContext(APIState)
    let [settingsDropdown, setSettingsDropdown] = useState(false);

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'DEVICE',
        drop: () => ({ name: team.uuid }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    if (!api.serverState)
        return null
    let serverState = api.serverState
    let adminSession = settings.adminSession!


    let keys = Object.keys(team.devices).sort()
    let devices = keys.map(k => team.devices[k])
    return <ListGroup key={team.uuid}>
        <ListGroupItem style={{
            textAlign: 'left',
            backgroundColor: serverState.lastWinner === team.uuid ? '#df691a' : 'grey',
            color: 'white',
            fontSize: '1.2em',
            userSelect: 'none',
        }} key={team.uuid} >
            {team.name}
            {' '}
            {serverState.lastWinner === team.uuid ? <Badge color="primary">BUZZED</Badge> : null}
            {team.excluded ? <Badge color="danger">EXCLUDED</Badge> : null}
            {!team.isLobby ? (
                <Dropdown style={{ float: 'right' }} toggle={() => setSettingsDropdown(!settingsDropdown)} isOpen={settingsDropdown}>
                    <DropdownToggle>
                        <GearIcon size="medium" />
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem onClick={() => {
                            api.platform?.send({
                                kind: 'Buzz',
                                session: adminSession,
                                teamId: team.uuid,
                            })
                        }}>Buzz</DropdownItem>
                        <DropdownItem onClick={() => {
                            let name = window.prompt("Team Name")
                            if (!name) return
                            api.platform?.send({
                                kind: 'RenameTeam',
                                session: adminSession,
                                teamId: team.uuid,
                                newName: name
                            })
                        }}>Rename</DropdownItem>
                        <DropdownItem onClick={() => {
                            let name = window.prompt("Team Alt Name")
                            if (!name) return
                            api.platform?.send({
                                kind: 'SetTeamAltName',
                                session: adminSession,
                                teamId: team.uuid,
                                name: name
                            })
                        }}>Set alt name</DropdownItem>
                        <DropdownItem onClick={() => {
                            api.platform?.send({
                                kind: 'ChangeExclusion',
                                session: adminSession,
                                teamId: team.uuid,
                                excluded: !team.excluded
                            })
                        }}>{team.excluded ? 'Un-Exclude' : 'Exclude'}</DropdownItem>
                        <DropdownItem onClick={() => {
                            if (window.prompt("Delete Team? (type 'y')") !== 'y')
                                return
                            api.platform?.send({
                                kind: 'DeleteTeam',
                                session: adminSession,
                                teamId: team.uuid,
                            })
                        }}>Delete</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            ) : null}
            {!team.isLobby ? (
                <span style={{ textAlign: 'right', float: 'right', paddingRight: '1em' }}>
                    <InputGroup>
                        <Input style={{ width: '4.5em' }} type="number" step="any" value={team.score} onChange={e => {
                            let points = parseFloat(e.target.value)
                            if (points == null || isNaN(points)) return; // input field does not work as expected
                            api.platform?.send({
                                kind: 'SetPoints',
                                session: adminSession,
                                teamId: team.uuid,
                                points
                            })
                        }} />
                        {/*<InputGroupText>
                            {team.score === 1 ? ' point' : ' points'}
                        </InputGroupText>*/}
                        <Button style={{ width: '2em', backgroundColor: "#df691a", color: "white" }} onClick={e => {
                            api.platform?.send({
                                kind: 'SetPoints',
                                session: adminSession,
                                teamId: team.uuid,
                                points: team.score + 1
                            })
                        }}>+</Button>
                    </InputGroup>
                </span>
            ) : null}
        </ListGroupItem>
        {api.config?.hideDevices ? null : devices.map(device => <TeamMember key={device.uuid} member={device} />)}
        <span style={{ display: canDrop ? 'block' : 'none' }} ref={drop}>
            <ListGroupItem style={{
                backgroundColor: isOver ? '#df691a' : undefined
            }}>Move Device to Team</ListGroupItem>
        </span>
    </ListGroup>
}

export const Teams: React.FC = () => {
    let api = useContext(APIState)
    if (!api.serverState)
        return null

    let serverState = api.serverState

    return <DndProvider backend={HTML5Backend}>
        <Row>
            {serverState.teams.map(team => {
                return <Col key={team.uuid} md={6} style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                    <TeamEntry team={team} />
                </Col>
            })}
        </Row>
    </DndProvider>
}